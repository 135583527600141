import "../../css/GlobalStyles.css";
import { ReactNode, memo, useEffect, useState } from "react";
import { Colors } from "../../utils/colors";
import { useSelector } from "react-redux";
import { getAccountState, LoginState } from "../../redux/slices/accountSlice";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MARKITAI } from "@markit/common.utils";
import TopHeader from "../TopHeader";
import Footer from "../Footer";

type LandingPagesWrapperProps = {
  helmetTitle: string;
  helmetMetaContent: string;
  children: ReactNode;
  preventLogoClick?: boolean;
};

const LandingPagesWrapper = (props: LandingPagesWrapperProps) => {
  const { helmetTitle, helmetMetaContent, children, preventLogoClick } = props;

  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn } = account;
  const navigate = useNavigate();

  // Add state for mobile nav
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  // Navigation effect
  useEffect(() => {
    if (loggedIn === LoginState.LOGGED_IN && accountData.inCreatorMode) {
      navigate("/home");
    }
  }, [loggedIn, accountData.inCreatorMode, navigate]);

  return (
    <div
      className={isMobileNavOpen ? "PreventAllScrolling" : ""}
      style={{
        backgroundColor: Colors.BLACK,
        overflowX: "hidden",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <title>{helmetTitle}</title>
        <meta name="description" content={helmetMetaContent} />
        <link rel="canonical" href={MARKITAI} />
      </Helmet>
      <TopHeader
        whiteLogo
        homePage
        preventLogoClick={preventLogoClick}
        onMobileNavOpenChange={setIsMobileNavOpen}
      />
      {children}
      <Footer />
    </div>
  );
};

export default memo(LandingPagesWrapper);
