import React, { memo, useCallback, useMemo } from "react";
import "../../css/GlobalStyles.css";
import { useSelector, useDispatch } from "react-redux";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import { MARKIT_CONTACT_US } from "@markit/common.utils";
import { useNavigate } from "../../hooks/useNavigate";
import { DataLoaders } from "../../redux/slices/dataSlice";
import TextingMadeHuman from "../../assets/LandingPage/TextingMadeHuman.png";
import BackgroundGradient from "../../assets/LandingPage/BackgroundGradient.png";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import { NavigationId } from "../../navigation/AppParamList";
import LandingPagesWrapper from "../../components/LandingPage/LandingPagesWrapper";
import { FloatingBubbles } from "../../components/AnimationContainers/FloatingBubbles";
import {
  AttachLinksAndImages,
  AutoReplies,
  NeverMissAText,
  ReachTheRightPeopleDesktop,
  ReachTheRightPeopleMobile,
  ScheduleAndForget,
  TextThousandsInSecondsDesktop,
  TextThousandsInSecondsMobile,
  TwoWayTextingDesktop,
  WelcomeFlows,
} from "../../assets/LandingPage/TextingPage/textingPageIndex";
import iMessageOnSteroidsMobile from "../../assets/LandingPage/iMessageOnSteroidsMobile.png";
import { HorizontalDivider } from "../../components/Dividers/HorizontalDivider";
import { VerticalDivider } from "../../components/Dividers/VerticalDivider";
import { useSlideUpAnimation } from "../../hooks/animationHooks/useSlideUpAnimation";
import { useWindowResize } from "../../hooks/useWindowResize";
import { checkIsDesktop } from "../../utils/screenResponsivenessUtils";

const TextingProductPage = memo(function TextingProductPageFn() {
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn } = account;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useSlideUpAnimation();
  const windowWidth = useWindowResize();

  const isDesktopSize = useMemo(
    () => checkIsDesktop(windowWidth),
    [windowWidth]
  );

  const styles = {
    button: {
      padding: isDesktopSize ? "12px 16px" : "9px 13px",
      borderRadius: isDesktopSize ? 22 : 18,
      width: "fit-content",
    },

    headerLabel: {
      fontSize: isDesktopSize ? 20 : 14,
      fontWeight: 500,
      paddingBottom: isDesktopSize ? 10 : 7,
    },

    headerSubtext: {
      fontSize: isDesktopSize ? 22 : 15,
      fontWeight: 500,
      color: Colors.GRAY14,
    },

    headerText: {
      fontSize: isDesktopSize ? 64 : 45,
      fontWeight: 500,
      color: Colors.WHITE,
    },

    largeImage: {
      width: "100%",
      height: "auto",
    },

    safeView: {
      paddingInline: 20,
    },

    smallHeaderSubtext: {
      fontSize: isDesktopSize ? 20 : 15,
      fontWeight: 500,
      color: Colors.GRAY14,
    },

    smallHeaderText: {
      fontSize: 32,
      fontWeight: 500,
      color: Colors.WHITE,
    },
  };

  const getStartedOnPress = useCallback(() => {
    if (loggedIn === LoginState.LOGGED_IN) {
      dispatch(DataLoaders.user(accountData.uid));
      navigate(`/u/${accountData.username}`, {
        state: { username: accountData.username },
      });
    } else {
      navigate(NavigationId.WELCOME);
    }
  }, [accountData.uid, accountData.username, dispatch, loggedIn, navigate]);

  const supportOnPress = useCallback(() => {
    window.open(MARKIT_CONTACT_US);
  }, []);

  return (
    <LandingPagesWrapper
      helmetTitle={"Texting | Markit AI"}
      helmetMetaContent={
        "Get the most out of your audience with perfectly timed campaigns, automations, two-way texting, and more."
      }
    >
      <div
        className="AlignedColumn Centering"
        style={{
          paddingBlock: 100,
          color: Colors.WHITE,
          height: 608,
          ...styles.safeView,
        }}
      >
        <FloatingBubbles />
        <p
          style={{
            fontSize: isDesktopSize ? 16 : 14,
            fontWeight: 500,
            color: Colors.BLUE5,
            zIndex: 100,
          }}
        >
          Texting with Markit
        </p>
        <h1
          style={{
            fontSize: isDesktopSize ? 96 : 63,
            fontWeight: 500,
            paddingTop: 10,
            paddingBottom: isDesktopSize ? 30 : 20,
            paddingInline: 0,
            textAlign: "center",
            letterSpacing: "-1px",
            zIndex: 100,
          }}
        >
          Next Level Texting
        </h1>
        <p
          style={{
            fontSize: isDesktopSize ? 20 : 15,
            fontWeight: 500,
            paddingBottom: isDesktopSize ? 40 : 60,
            textAlign: "center",
            zIndex: 100,
          }}
        >
          Get the most out of your audience with {isDesktopSize ? null : <br />}
          perfectly timed campaigns, {isDesktopSize ? <br /> : null}{" "}
          automations, {isDesktopSize ? null : <br />}
          two-way texting, and more.
        </p>
      </div>
      {/* Fold 2 */}
      <div
        style={{
          ...styles.safeView,
          backgroundColor: Colors.BLACK,
          paddingTop: 80,
          paddingBottom: isDesktopSize ? 100 : 0,
        }}
      >
        <div style={{ maxWidth: 1152, margin: "0 auto" }}>
          <div className="ColumnNormal" style={{ gap: isDesktopSize ? 0 : 50 }}>
            <div className="ColumnNormal">
              <span
                style={{
                  ...styles.headerLabel,
                  color: Colors.BLUE5,
                }}
              >
                SMS Campaigns
              </span>
              <div
                className="ColumnNormal"
                style={{ maxWidth: isDesktopSize ? 633 : "auto", gap: 30 }}
              >
                <h2 style={styles.headerText}>
                  Reach the right {isDesktopSize ? null : <br />}people{" "}
                  {isDesktopSize ? <br /> : null}
                  at the {isDesktopSize ? null : <br />}perfect time.
                </h2>

                <p style={{ ...styles.headerSubtext }}>
                  Create, send, and schedule hundreds of targeted text
                  campaigns. View analytics and manage all of your texts on
                  desktop and mobile.
                </p>
                <div
                  className="AlignedRow"
                  style={{ gap: isDesktopSize ? 14 : 10 }}
                >
                  <RectangleButton
                    buttonLabel="Start Texting"
                    onPress={getStartedOnPress}
                    altColor={Colors.WHITE}
                    altTextColor={Colors.BLACK}
                    iconRight={
                      <Icon
                        icon="ion:chevron-forward"
                        height={16}
                        color={Colors.BLACK}
                      />
                    }
                    containerStyles={styles.button}
                  />
                  <RectangleButton
                    buttonLabel="Learn More"
                    onPress={supportOnPress}
                    altColor={Colors.BLACK}
                    altTextColor={Colors.WHITE}
                    containerStyles={{
                      ...styles.button,
                      border: `1px solid ${Colors.WHITE}`,
                    }}
                  />
                </div>
              </div>
            </div>
            <img
              src={
                isDesktopSize
                  ? ReachTheRightPeopleDesktop
                  : ReachTheRightPeopleMobile
              }
              alt="Campaigns dashboard"
              className="slide-up"
              style={{
                ...styles.largeImage,
                objectFit: "contain",
                maxWidth: 1152,
              }}
            />
          </div>
        </div>
      </div>
      {isDesktopSize ? (
        <HorizontalDivider
          altWidth={0.5}
          altColor={Colors.BLACK1}
          containerStyles={{ maxWidth: 1152, margin: "0 auto" }}
        />
      ) : null}
      {/* Fold 3 */}
      <div
        className="ColumnNormal"
        style={{
          ...styles.safeView,
          backgroundColor: Colors.BLACK,
          paddingTop: isDesktopSize ? 100 : 60,
          paddingBottom: isDesktopSize ? 80 : 60,
          maxWidth: 1152,
          margin: "0 auto",
          gap: isDesktopSize ? 80 : 40,
        }}
      >
        <div className="ColumnNormal">
          <div
            className={isDesktopSize ? "AlignedRowSpaced" : "ColumnNormal"}
            style={{ gap: 30 }}
          >
            <h2 style={styles.headerText}>
              Text Thousands
              <br />
              In Seconds.
            </h2>
            <p
              style={{
                ...styles.headerSubtext,
                maxWidth: isDesktopSize ? 587 : "auto",
              }}
            >
              Powerful and intuitive text drafting tools enable you to send
              personalized texts to thousands of people in seconds, with zero
              effort.
            </p>
          </div>
        </div>
        <img
          src={
            isDesktopSize
              ? TextThousandsInSecondsDesktop
              : TextThousandsInSecondsMobile
          }
          alt="Mass text mobile annotated"
          className="slide-up"
          style={{
            ...styles.largeImage,
            objectFit: "contain",
            maxWidth: 1152,
          }}
        />
      </div>
      <HorizontalDivider
        altWidth={0.5}
        altColor={Colors.BLACK1}
        containerStyles={{ maxWidth: 1152, margin: "0 auto" }}
      />
      {/* Fold 4 */}
      <div
        className={isDesktopSize ? "RowNormal" : "ColumnNormal"}
        style={{
          ...styles.safeView,
          backgroundColor: Colors.BLACK,
          maxWidth: 1152,
          margin: "0 auto",
        }}
      >
        <div
          className={isDesktopSize ? "RowNormal" : "ColumnNormal"}
          style={{ gap: isDesktopSize ? 60 : 40 }}
        >
          <div
            className="ColumnNormalSpaced"
            style={{
              maxWidth: 516,
              paddingTop: 40,
              paddingBottom: isDesktopSize ? 117 : 0,
            }}
          >
            <div className="ColumnNormal">
              <h2 style={{ ...styles.smallHeaderText, paddingBottom: 14 }}>
                Schedule & Forget
              </h2>
              <p
                style={{
                  ...styles.smallHeaderSubtext,
                  maxWidth: isDesktopSize ? 480 : "auto",
                }}
              >
                Schedule your texts to send in the future. We give built in
                recommendations for scheduled texts when managing events and
                more.
              </p>
            </div>
            <img
              src={ScheduleAndForget}
              alt="analog clock"
              className="slide-up"
              style={{
                ...styles.largeImage,
                objectFit: "contain",
                maxWidth: 409,
                marginTop: isDesktopSize ? 0 : 40,
              }}
            />
          </div>
          {isDesktopSize ? (
            <VerticalDivider
              height={756}
              color={Colors.BLACK1}
              thickness={0.5}
            />
          ) : (
            <HorizontalDivider
              altWidth={0.5}
              altColor={Colors.BLACK1}
              containerStyles={{ maxWidth: 1152 }}
            />
          )}
          <div
            className="ColumnNormalSpaced"
            style={{ maxWidth: 516, paddingTop: 40 }}
          >
            <div className="ColumnNormal">
              <h2 style={{ ...styles.smallHeaderText, paddingBottom: 14 }}>
                Attach Links and Images
              </h2>
              <p
                style={{
                  ...styles.smallHeaderSubtext,
                  maxWidth: isDesktopSize ? 480 : "auto",
                }}
              >
                Attach images to your texts and send discount ticket links,
                membership links, and more.
              </p>
            </div>
            <img
              src={AttachLinksAndImages}
              alt="concert covered by attachment icons"
              className="slide-up"
              style={{
                ...styles.largeImage,
                objectFit: "contain",
                maxWidth: 506,
                marginTop: isDesktopSize ? 0 : 40,
                paddingBottom: isDesktopSize ? 60 : 0,
              }}
            />
          </div>
        </div>
      </div>
      {isDesktopSize ? (
        <HorizontalDivider
          altWidth={0.5}
          altColor={Colors.BLACK1}
          containerStyles={{ maxWidth: 1152, margin: "0 auto" }}
        />
      ) : null}
      {/* Fold 5 */}
      <div
        className="ColumnNormal"
        style={{
          ...styles.safeView,
          backgroundColor: Colors.BLACK,
          paddingTop: isDesktopSize ? 100 : 60,
          paddingBottom: isDesktopSize ? 100 : 60,
          maxWidth: 1152,
          margin: "0 auto",
          gap: isDesktopSize ? 80 : 50,
        }}
      >
        <div className={isDesktopSize ? "AlignedColumn" : "ColumnNormal"}>
          <Icon
            icon="ion:chatbubbles"
            height={40}
            color={Colors.BLUE5}
            style={{ paddingBottom: 10 }}
          />
          <h2
            style={{
              ...styles.headerText,
              paddingBottom: isDesktopSize ? 30 : 20,
            }}
          >
            Two Way Texting
          </h2>
          <p
            style={{
              ...styles.headerSubtext,
              textAlign: isDesktopSize ? "center" : "start",
              maxWidth: isDesktopSize ? 700 : "auto",
            }}
          >
            Create your personal authentic channel to connect with your top
            fans. And because it&apos;s texts, fans can reply like they&apos;re
            chatting with a friend.
          </p>
        </div>
        <img
          src={isDesktopSize ? TwoWayTextingDesktop : iMessageOnSteroidsMobile}
          alt="Two way texting panel"
          className="slide-up"
          style={{
            ...styles.largeImage,
            objectFit: "contain",
            maxWidth: 1152,
          }}
        />
      </div>
      {isDesktopSize ? (
        <HorizontalDivider
          altWidth={0.5}
          altColor={Colors.BLACK1}
          containerStyles={{ maxWidth: 1152, margin: "0 auto" }}
        />
      ) : null}
      {/* Fold 6 */}
      <div
        style={{
          ...styles.safeView,
          backgroundColor: Colors.BLACK,
          paddingTop: isDesktopSize ? 100 : 0,
          paddingBottom: isDesktopSize ? 100 : 60,
        }}
      >
        <div style={{ maxWidth: 1152, margin: "0 auto" }}>
          <div
            className={isDesktopSize ? "RowNormal" : "ColumnNormal"}
            style={{ gap: isDesktopSize ? 80 : 20 }}
          >
            <div className="ColumnNormal">
              <span
                style={{
                  ...styles.headerLabel,
                  color: Colors.GREEN2,
                }}
              >
                Automations
              </span>
              <div className="ColumnNormal" style={{ gap: 30 }}>
                <h2 style={styles.headerText}>
                  Never Miss a Text
                  <br />
                  with Automations
                </h2>

                <p style={{ ...styles.headerSubtext, maxWidth: 380 }}>
                  Send timely texts with smart triggers & tracking. Perfect for
                  events, capturing data, and tracking form submissions—stay
                  connected effortlessly.
                </p>
                <div
                  className="AlignedRow"
                  style={{ gap: isDesktopSize ? 14 : 10 }}
                >
                  <RectangleButton
                    buttonLabel="Get Started"
                    onPress={getStartedOnPress}
                    altColor={Colors.WHITE}
                    altTextColor={Colors.BLACK}
                    iconRight={
                      <Icon
                        icon="ion:chevron-forward"
                        height={16}
                        color={Colors.BLACK}
                      />
                    }
                    containerStyles={styles.button}
                  />
                </div>
              </div>
            </div>
            <img
              src={NeverMissAText}
              alt="Automation item"
              className="slide-up"
              style={{
                ...styles.largeImage,
                objectFit: "contain",
                maxWidth: 587,
              }}
            />
          </div>
        </div>
      </div>
      <HorizontalDivider
        altWidth={0.5}
        altColor={Colors.BLACK1}
        containerStyles={{ maxWidth: 1152, margin: "0 auto" }}
      />
      {/* Fold 7 */}
      <div
        className={isDesktopSize ? "RowNormal" : "ColumnNormal"}
        style={{
          ...styles.safeView,
          backgroundColor: Colors.BLACK,
          maxWidth: 1152,
          margin: "0 auto",
          paddingBottom: isDesktopSize ? 80 : 60,
        }}
      >
        <div
          className={isDesktopSize ? "RowNormal" : "ColumnNormal"}
          style={{ gap: isDesktopSize ? 60 : 40 }}
        >
          <div
            className="ColumnNormalSpaced"
            style={{
              maxWidth: 516,
              paddingTop: isDesktopSize ? 60 : 40,
              paddingBottom: isDesktopSize ? 20 : 0,
            }}
          >
            <div className="ColumnNormal">
              <h2 style={{ ...styles.smallHeaderText, paddingBottom: 14 }}>
                Welcome Flows
              </h2>
              <p
                style={{
                  ...styles.smallHeaderSubtext,
                  maxWidth: isDesktopSize ? 368 : "auto",
                }}
              >
                Save time and automate responses to make sure you send an
                immediate text when people follow you.
              </p>
            </div>
            <img
              src={WelcomeFlows}
              alt="Welcome flow items"
              className="slide-up"
              style={{
                ...styles.largeImage,
                objectFit: "contain",
                maxWidth: 511,
                marginTop: isDesktopSize ? 0 : 40,
                paddingBottom: isDesktopSize ? 120 : 0,
              }}
            />
          </div>
          {isDesktopSize ? (
            <VerticalDivider
              height={797}
              color={Colors.BLACK1}
              thickness={0.5}
            />
          ) : (
            <HorizontalDivider
              altWidth={0.5}
              altColor={Colors.BLACK1}
              containerStyles={{ maxWidth: 1152 }}
            />
          )}
          <div
            className="ColumnNormalSpaced"
            style={{ maxWidth: 516, paddingTop: isDesktopSize ? 60 : 0 }}
          >
            <div className="ColumnNormal">
              <h2 style={{ ...styles.smallHeaderText, paddingBottom: 14 }}>
                Auto Replies
              </h2>
              <p
                style={{
                  ...styles.smallHeaderSubtext,
                  maxWidth: isDesktopSize ? 480 : "auto",
                }}
              >
                Customize the message people get when they directly text your
                phone number, as well as auto replies, keywords, and actions.
              </p>
            </div>
            <img
              src={AutoReplies}
              alt="Text conversation with auto reply"
              className="slide-up"
              style={{
                ...styles.largeImage,
                objectFit: "contain",
                maxWidth: 416,
                alignSelf: "center",
                marginTop: isDesktopSize ? 0 : 50,
              }}
            />
          </div>
        </div>
      </div>
      {/* Fold 8 */}
      <div
        className={"AlignedColumn"}
        style={{
          paddingTop: isDesktopSize ? 80 : 60,
          paddingBottom: isDesktopSize ? 80 : 60,
          gap: isDesktopSize ? 30 : 20,
          backgroundImage: `url(${BackgroundGradient})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h2
          style={{
            ...styles.headerText,
            textAlign: "center",
          }}
        >
          Your Value
          <br />
          Delivered{isDesktopSize ? null : <br />} Directly.
          {isDesktopSize ? null : <br />}
        </h2>
        <p style={{ ...styles.headerSubtext }}>
          Markit is free to use. Upgrade for more texting and
          <br />
          additional features. Available on Desktop or Mobile.
        </p>
        <RectangleButton
          buttonLabel={
            <p
              className="AlignedRowSelect"
              style={{
                fontSize: isDesktopSize ? 16 : 12,
                whiteSpace: "nowrap",
                fontWeight: 500,
              }}
            >
              Start Texting
            </p>
          }
          onPress={getStartedOnPress}
          altColor={Colors.BLUE5}
          altTextColor={Colors.WHITE}
          containerStyles={{
            ...styles.button,
            alignSelf: "center",
          }}
          iconRight={
            <Icon icon="ion:chevron-forward" height={16} color={Colors.WHITE} />
          }
        />
        <img
          src={TextingMadeHuman}
          alt="Creator Subscriber Collage"
          className="slide-up"
          style={{
            ...styles.largeImage,
            paddingTop: isDesktopSize ? 30 : 40,
            objectFit: "contain",
            maxWidth: isDesktopSize ? 480 : 336,
          }}
        />
      </div>
    </LandingPagesWrapper>
  );
});

export default TextingProductPage;
