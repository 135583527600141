import { CSSProperties, ReactNode, useCallback } from "react";
import { Colors } from "../../utils/colors";

interface MessageBubbleProps {
  text: string | ReactNode;
  tailPosition?: "bottomRight" | "bottomLeft";
  color?: string;
  width?: number; // use width if you want text to be wrapped to multiple lines
  onPress?: () => void;
  visualStyles?: CSSProperties;
  containerStyles?: CSSProperties;
}

export const MessageBubble = ({
  text,
  tailPosition = "bottomRight",
  color = Colors.BLUE5,
  width,
  onPress,
  visualStyles,
  containerStyles,
}: MessageBubbleProps) => {
  const styles = {
    bubbleView: {
      backgroundColor: color,
      display: "inline-block",
      whiteSpace: width ? "normal" : "nowrap", // if no width is provided, the text will be wrapped to multiple lines
      width: width,
      overflow: "hidden",
    },

    bubbleText: {
      whiteSpace: "normal",
    },
  };

  const getTailStyles = useCallback(() => {
    const tailStyles: CSSProperties = {
      position: "absolute",
    };

    switch (tailPosition) {
      case "bottomLeft":
        tailStyles.left = -5;
        tailStyles.bottom = 4;
        break;
      case "bottomRight":
        tailStyles.right = -5;
        tailStyles.bottom = 4;
        break;
    }

    return tailStyles;
  }, [tailPosition]);

  const renderTail = useCallback(() => {
    switch (tailPosition) {
      case "bottomLeft":
        return (
          <svg
            width="13"
            height="16"
            viewBox="0 0 13 16"
            style={getTailStyles()}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.53674e-07 15.6375C6.44507 17.3933 11.3521 12.3454 13 9.60198L12.8169 1.37171C11.0469 1.9204 7.21408 2.41421 6.04225 0C6.22535 9.87633 1.28169 14.8145 9.53674e-07 15.6375Z"
              fill={color}
            />
          </svg>
        );
      case "bottomRight":
        return (
          <svg
            width="14"
            height="17"
            viewBox="0 0 14 17"
            style={getTailStyles()}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 16.6068C7.05493 18.3625 2.14789 13.3146 0.5 10.5712L0.683099 2.34095C2.45305 2.88963 6.28592 3.38345 7.45775 0.969238C7.27465 10.8456 12.2183 15.7837 13.5 16.6068Z"
              fill={color}
            />
          </svg>
        );
    }
  }, [color, getTailStyles, tailPosition]);

  return (
    <div
      onClick={onPress}
      style={{
        position: "relative",
        display: "inline-block",
        cursor: onPress ? "pointer" : "default",
        transition: "opacity 0.2s ease",
        ...visualStyles,
      }}
      onMouseEnter={(e) => (e.currentTarget.style.opacity = "0.7")}
      onMouseLeave={(e) => (e.currentTarget.style.opacity = "1")}
    >
      <span style={{ ...styles.bubbleView, ...containerStyles }}>{text}</span>
      {renderTail()}
    </div>
  );
};
