import { FC, useState } from "react";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { getAccountState, LoginState } from "../../redux/slices/accountSlice";
import { DropdownMenuItem } from "../DropdownMenu";
import { useNavigate } from "react-router-dom";
import FatHeaderActionButton from "../Buttons/FatHeaderActionButton";
import { useSelector } from "react-redux";

interface MobileNavDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  resourcesDropdownItems: DropdownMenuItem[];
  aboutDropdownItems: DropdownMenuItem[];
  headerNavOnPress: (item: string) => void;
  exploreOnPress: () => void;
  signUpUser: () => void;
}

const MobileNavDrawer: FC<MobileNavDrawerProps> = ({
  isOpen,
  onClose,
  resourcesDropdownItems,
  aboutDropdownItems,
  headerNavOnPress,
  exploreOnPress,
  signUpUser,
}) => {
  const { account } = useSelector(getAccountState);
  const { loggedIn } = account;
  const navigate = useNavigate();
  const [expandedSection, setExpandedSection] = useState<string | null>(null);

  const styles = {
    overlayView: {
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      opacity: isOpen ? 1 : 0,
    },

    buttonStyle: {
      paddingInline: 12,
      paddingBlock: 10,
      height: 40,
    },
  };

  const toggleSection = (section: string) => {
    if (expandedSection === section) {
      setExpandedSection(null);
    } else {
      setExpandedSection(section);
    }
  };

  const handleNavItemClick = (item: string) => {
    if (item === "Home") {
      navigate("/");
      onClose();
    } else if (item === "Pricing" || item === "Product") {
      headerNavOnPress(item);
      onClose();
    }
  };

  const handleDropdownItemClick = (onPress: () => void) => {
    onPress();
    onClose();
  };

  return (
    <>
      {/* Add overlay */}
      <div
        style={{
          ...styles.overlayView,
          position: "fixed",
          visibility: isOpen ? "visible" : "hidden",
          background:
            "linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,0.4) 100%)",
          transition: "opacity 0.3s ease-in-out, visibility 0.3s ease-in-out",
          zIndex: 999,
        }}
        onClick={onClose}
      />
      {/* Drawer content - simplified styles */}
      <div
        className="ColumnNormal"
        style={{
          ...styles.overlayView,
          position: "fixed",
          visibility: isOpen ? "visible" : "hidden",
          maxWidth: "100vw",
          overflow: "auto",
          zIndex: 1000,
          paddingInline: "20px",
          boxSizing: "border-box",
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <div
            className="AlignedRowSpaced"
            style={{
              width: "100%",
              paddingTop: 14,
            }}
          >
            <div
              className="RowNormal"
              style={{
                gap: 10,
              }}
            >
              <FatHeaderActionButton
                title="Discover"
                icon={
                  <Icon icon={"ion:compass"} height={15} color={Colors.WHITE} />
                }
                onPress={() => {
                  exploreOnPress();
                  onClose();
                }}
                containerStyles={{
                  ...styles.buttonStyle,
                  color: Colors.WHITE,
                  backgroundColor: Colors.BLACK,
                  width: "fit-content",
                }}
              />

              {loggedIn !== LoginState.LOGGED_IN && (
                <FatHeaderActionButton
                  title="Sign Up"
                  onPress={() => {
                    signUpUser();
                    onClose();
                  }}
                  containerStyles={{
                    ...styles.buttonStyle,
                    color: Colors.BLACK,
                    backgroundColor: Colors.WHITE,
                    width: "fit-content",
                  }}
                />
              )}
            </div>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={onClose}
            >
              <Icon icon="mdi:close" height={24} color={Colors.WHITE} />
            </div>
          </div>
        </div>
        {/* Navigation Items */}
        <NavItem label="Home" onClick={() => handleNavItemClick("Home")} />
        {/* Product  */}
        <NavItem
          label="Product"
          onClick={() => handleNavItemClick("Product")}
        />

        {/* Resources Dropdown */}
        <NavItemWithDropdown
          label="Resources"
          isExpanded={expandedSection === "Resources"}
          toggleExpand={() => toggleSection("Resources")}
          items={resourcesDropdownItems}
          onItemClick={handleDropdownItemClick}
        />

        {/* About Dropdown */}
        <NavItemWithDropdown
          label="About"
          isExpanded={expandedSection === "About"}
          toggleExpand={() => toggleSection("About")}
          items={aboutDropdownItems}
          onItemClick={handleDropdownItemClick}
        />

        {/* Pricing */}
        <NavItem
          label="Pricing"
          onClick={() => handleNavItemClick("Pricing")}
        />
      </div>
    </>
  );
};

interface NavItemProps {
  label: string;
  onClick: () => void;
}

const NavItem: FC<NavItemProps> = ({ label, onClick }) => {
  return (
    <div
      style={{
        color: Colors.WHITE,
        fontSize: 24,
        fontWeight: 500,
        padding: "15px 0",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

interface NavItemWithDropdownProps {
  label: string;
  isExpanded: boolean;
  toggleExpand: () => void;
  items: DropdownMenuItem[];
  onItemClick: (onPress: () => void) => void;
}

const NavItemWithDropdown: FC<NavItemWithDropdownProps> = ({
  label,
  isExpanded,
  toggleExpand,
  items,
  onItemClick,
}) => {
  return (
    <div>
      <div
        className="AlignedRow"
        style={{
          color: Colors.WHITE,
          fontSize: 24,
          fontWeight: 500,
          padding: "15px 0",
          cursor: "pointer",
          gap: 7,
        }}
        onClick={toggleExpand}
      >
        {label}
        <Icon
          icon={isExpanded ? "mdi:chevron-up" : "mdi:chevron-down"}
          height={30}
          color={Colors.WHITE}
        />
      </div>
      {isExpanded ? (
        <div>
          {items.map((item) => (
            <div
              key={item.key}
              style={{
                color: Colors.GRAY1,
                fontSize: 20,
                padding: "15px 0",
                cursor: "pointer",
              }}
              onClick={() => onItemClick(item.onPress)}
            >
              {item.title}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default MobileNavDrawer;
