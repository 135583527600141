import { memo, useState } from "react";
import { Colors } from "../../utils/colors";
import { useNavigate } from "../../hooks/useNavigate";
import { isDesktop } from "react-device-detect";
import LabelButton from "../../components/Buttons/LabelButton";
import SubscriptionPlans from "../../components/Subscription/SubscriptionPlans";
import { NavigationId } from "../../navigation/AppParamList";
import { LoginStage } from "../Login/LoginWelcome";
import LandingPagesWrapper from "../../components/LandingPage/LandingPagesWrapper";

const PricingPage = memo(function PricingPageFn() {
  const navigate = useNavigate();
  const [selectedCardOption, setSelectedCardOption] = useState(2);

  return (
    <LandingPagesWrapper
      helmetTitle={"Pricing | Markit AI"}
      helmetMetaContent={
        "Explore more texting and dedicated support with Markit+"
      }
    >
      <div
        className="AlignedColumn"
        style={{
          paddingTop: 60,
          paddingBottom: 100,
          gap: isDesktop ? 60 : 80,
          color: Colors.WHITE,
        }}
      >
        <div
          className="AlignedColumn"
          style={{
            gap: isDesktop ? 30 : 21,
            width: isDesktop ? 926 : undefined,
            paddingInline: isDesktop ? 0 : 14,
          }}
        >
          <LabelButton
            text="Pricing"
            color={Colors.WHITE}
            backgroundColor={Colors.GRAY10}
            containerStyles={{
              borderRadius: 22,
              paddingInline: 16,
              paddingBlock: 11.6,
              border: "none",
            }}
          />
          <span
            style={{
              fontSize: isDesktop ? 64 : 45,
              fontWeight: 500,
              textAlign: "center",
              letterSpacing: "-1px",
            }}
          >
            Find the right plan
            <br />
            for your audience
          </span>
          <span
            style={{
              fontSize: isDesktop ? 22 : 14,
              color: Colors.GRAY14,
              textAlign: "center",
              width: isDesktop ? 684 : undefined,
            }}
          >
            When you sign up for Markit, you get free texts and growth features
            for free. Sign up for Markit+ for additional features and more
            texting
          </span>
        </div>
        <div style={{ paddingInline: isDesktop ? 180 : 14 }}>
          <SubscriptionPlans
            selectedCardOption={selectedCardOption}
            setSelectedCardOption={setSelectedCardOption}
            itemOnPress={() =>
              navigate(NavigationId.SIGN_IN, {
                state: { preLoginStage: LoginStage.EMAIL_SIGNUP },
              })
            }
            homePage
          />
        </div>
      </div>
    </LandingPagesWrapper>
  );
});

export default PricingPage;
