import { useEffect } from "react";

/**
 * Hook to add slide-up animation functionality to elements with the "slide-up" class
 * @param threshold - Intersection observer threshold (0-1), defaults to 0.1
 */
export const useSlideUpAnimation = (threshold = 0.1) => {
  useEffect(() => {
    // Add CSS styles for the animation
    const style = document.createElement("style");
    style.textContent = `
      .slide-up {
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 1s ease-out, transform 0.8s ease-out;
      }
      .slide-up.visible {
        opacity: 1;
        transform: translateY(0);
      }
    `;
    document.head.appendChild(style);

    // Create intersection observer for slide-up animations
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold }
    );

    // Function to observe elements
    const observeElements = () => {
      document.querySelectorAll(".slide-up").forEach((el) => {
        if (!el.classList.contains("visible")) {
          observer.observe(el);
        }
      });
    };

    // Initial observation
    observeElements();

    // Create mutation observer to watch for DOM changes
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          observeElements();
        }
      });
    });

    // Start observing the document body for changes
    mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Cleanup function
    return () => {
      style.remove();
      observer.disconnect();
      mutationObserver.disconnect();
    };
  }, [threshold]);
};
