import React, {
  memo,
  useCallback,
  useRef,
  useEffect,
  useState,
  useMemo,
} from "react";
import "../../css/DefaultLandingPage.css";
import "../../css/GlobalStyles.css";
import { useSelector, useDispatch } from "react-redux";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import MarkitHeaderLogo from "../../components/MarkitHeaderLogo";
import { MARKIT_CONTACT_US } from "@markit/common.utils";
import { useNavigate } from "../../hooks/useNavigate";
import { DataLoaders } from "../../redux/slices/dataSlice";
import {
  AcesCarousel,
  ASAPRockyCarousel,
  BUCarousel,
  CitizensCarousel,
  CAMHCarousel,
  DopeCarousel,
  MeetIRLCarousel,
  OldDominionCarousel,
  OSLCarousel,
  RecordsCarousel,
  RestlessRoadCarousel,
  SetItOffCarousel,
  SidetalkCarousel,
  StateChampsCarousel,
  TechstarsCarousel,
  TuftsCarousel,
} from "../../assets/LandingPage/Carousel/carouselIndex";
import {
  JessicaBlumMembership,
  JessicaBlumMembershipMobile,
  ZaneCarterMembership,
  ZaneCarterMembershipMobile,
  AlexJohnsonMembership,
  AlexJohnsonMembershipMobile,
} from "../../assets/LandingPage/MembershipCarousel/membershipCarouselIndex";
import YourBrandYourWay from "../../assets/LandingPage/YourBrandYourWay.png";
import PricingThatScalesWithYou from "../../assets/LandingPage/PricingThatScalesWithYou.png";
import ReachYourFansInstantly from "../../assets/LandingPage/ReachYourFansInstantly.png";
import OldDominion from "../../assets/LandingPage/OldDominion.png";
import Aces from "../../assets/LandingPage/Aces.png";
import iMessageOnSteroids from "../../assets/LandingPage/iMessageOnSteroids.png";
import iMessageOnSteroidsMobile from "../../assets/LandingPage/iMessageOnSteroidsMobile.png";
import JessicaSubscriberQuote from "../../assets/LandingPage/JessicaSubscriberQuote.png";
import JessicaSubscriberQuoteMobile from "../../assets/LandingPage/JessicaSubscriberQuoteMobile.png";
import TextingMadeHuman from "../../assets/LandingPage/TextingMadeHuman.png";
import BackgroundGradient from "../../assets/LandingPage/BackgroundGradient.png";
import { isDesktop, isMobile } from "react-device-detect";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import MarkitFullLogoBlueWhite from "../../assets/MarkitFullLogoBlueWhite.png";
import {
  LandingPageNavigationId,
  NavigationId,
} from "../../navigation/AppParamList";
import CarouselContainer, {
  CarouselItem,
} from "../../components/AnimationContainers/CarouselContainer";
import { Carousel } from "react-responsive-carousel";
import { useSlideUpAnimation } from "../../hooks/animationHooks/useSlideUpAnimation";
import LandingPagesWrapper from "../../components/LandingPage/LandingPagesWrapper";
import { MessageBubble } from "../../components/AnimationContainers/MessageBubble";
import { checkIsDesktop } from "../../utils/screenResponsivenessUtils";
import { useWindowResize } from "../../hooks/useWindowResize";

const DefaultLandingPage = memo(function DefaultLandingPageFn() {
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn } = account;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useSlideUpAnimation();
  const windowWidth = useWindowResize();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [scrollPosition, setScrollPosition] = useState(0);

  // Refs to store the logo container and scroll interval ID
  const logoContainerRef = useRef<HTMLDivElement>(null);
  const scrollIntervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const isDesktopSize = useMemo(
    () => checkIsDesktop(windowWidth),
    [windowWidth]
  );

  const fanWords = ["Network", "Biggest Fans", "Loyal Community"];

  const styles = {
    button: {
      padding: isDesktopSize ? "12px 16px" : "9px 13px",
      borderRadius: isDesktopSize ? 22 : 18,
      width: "fit-content",
    },

    gradientTopLeft: {
      width: "100%",
      height: isDesktopSize ? 100 : 53,
      background: "linear-gradient(to top left, black 50%, white 50%)",
    },

    gradientBottomRight: {
      width: "100%",
      height: isDesktopSize ? 100 : 53,
      background: "linear-gradient(to bottom right, black 50%, white 50%)",
    },

    headerLabel: {
      fontSize: isDesktopSize ? 20 : 14,
      fontWeight: 500,
      paddingBottom: isDesktopSize ? 10 : 7,
    },

    headerSubtext: {
      fontSize: isDesktopSize ? 22 : 15,
      fontWeight: 500,
      color: Colors.GRAY14,
    },

    headerText: {
      fontSize: isDesktopSize ? 64 : 48,
      fontWeight: 500,
      lineHeight: 1.1,
    },

    largeImage: {
      width: "100%",
      height: "auto",
    },

    safeView: {
      paddingInline: 20,
    },

    slideUpAnimation: {
      opacity: 0,
      transform: "translateY(20px)",
      transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
    },

    slideUpVisible: {
      opacity: 1,
      transform: "translateY(0)",
    },

    testimonialBox: {
      backgroundColor: Colors.GRAY10,
      padding: 30,
      borderRadius: 20,
      height: isDesktopSize ? 253 : "auto",
      gap: isDesktopSize ? 0 : 30,
    },

    testimonialText: {
      fontSize: isDesktopSize ? 24 : 20,
      color: Colors.WHITE,
    },

    testimonialImage: {
      height: isDesktopSize ? 60 : 42,
    },

    testimonialName: {
      fontSize: isDesktopSize ? 24 : 16,
      fontWeight: 500,
      color: Colors.GRAY1,
    },
  };

  // Handle mouse movement for interactive effects on desktop
  useEffect(() => {
    if (isMobile) return; // Skip mouse tracking on mobile

    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({
        x: event.clientX,
        y: event.clientY,
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // Handle scroll position tracking for mobile
  useEffect(() => {
    if (isDesktop) return; // Only track scrolling on mobile

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Canvas dot grid animation
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Set canvas dimensions
    const updateCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    updateCanvasSize();
    window.addEventListener("resize", updateCanvasSize);

    // Create dot grid
    const dotSize = isDesktop ? 1 : 0.7;
    const spacing = isDesktop ? 30 : 20;
    const dots: { x: number; y: number; baseY: number; velocity: number }[] =
      [];

    // Initialize dots
    for (let x = 0; x < canvas.width; x += spacing) {
      for (let y = 0; y < canvas.height; y += spacing) {
        dots.push({
          x,
          y,
          baseY: y,
          velocity: 0.1 + Math.random() * 0.2,
        });
      }
    }

    // Animation loop
    let animationFrameId: number;

    const render = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw dots
      dots.forEach((dot) => {
        let opacity = 0.15;

        if (isMobile) {
          // On mobile, use scroll position to determine dot opacity
          // Calculate the dot's position relative to the viewport
          const dotViewportY = dot.y - window.scrollY;
          // Move spotlight focus to the top of the screen instead of center
          // Use 1/4 of the viewport height as the focal point
          const viewportFocus = window.innerHeight / 4;
          const distanceFromFocus = Math.abs(dotViewportY - viewportFocus);
          // Smaller spotlight size - reduce to 1/3 of the viewport height
          const maxDistance = window.innerHeight / 5;

          if (distanceFromFocus < maxDistance) {
            // Enhanced spotlight effect with stronger contrast
            // Use a non-linear curve to create a more dramatic spotlight
            const normalizedDistance = distanceFromFocus / maxDistance;
            // Sharper exponential falloff for more distinct spotlight
            opacity = 0.05 + (1 - Math.pow(normalizedDistance, 2.5)) * 0.75;

            // Add subtle movement based on scroll position
            dot.y =
              dot.baseY +
              Math.sin((scrollPosition + dot.x) * 0.01 * dot.velocity) * 3;
          } else {
            // Make dots outside the spotlight nearly invisible
            opacity = 0.01;
            // Subtle wave effect for dots far from center
            dot.y =
              dot.baseY +
              Math.sin((scrollPosition + dot.x) * 0.01 * dot.velocity) * 1;
          }
        } else {
          // Desktop behavior - react to mouse position
          // Calculate distance from mouse
          const dx = mousePosition.x - dot.x;
          const dy = mousePosition.y - dot.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          // Interactive effect based on mouse position
          const maxDistance = 300;

          if (distance < maxDistance) {
            opacity = 0.15 + (1 - distance / maxDistance) * 0.75;

            // Subtle movement effect
            dot.y =
              dot.baseY +
              Math.sin(Date.now() * 0.001 * dot.velocity) *
                3 *
                (1 - distance / maxDistance);
          } else {
            // Subtle wave effect for dots far from mouse
            dot.y = dot.baseY + Math.sin(Date.now() * 0.001 * dot.velocity) * 1;
          }
        }

        ctx.fillStyle = `rgba(255, 255, 255, ${opacity})`;
        ctx.beginPath();
        ctx.arc(dot.x, dot.y, dotSize, 0, Math.PI * 2);
        ctx.fill();
      });

      animationFrameId = window.requestAnimationFrame(render);
    };

    render();

    return () => {
      window.removeEventListener("resize", updateCanvasSize);
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [mousePosition, scrollPosition]);

  // Carousel animation
  useEffect(() => {
    const scrollContainer = logoContainerRef.current;
    if (!scrollContainer) return;

    // Clear any existing interval
    if (scrollIntervalIdRef.current) {
      clearInterval(scrollIntervalIdRef.current);
      scrollIntervalIdRef.current = null;
    }

    const scroll = () => {
      const scrollWidth = scrollContainer.scrollWidth;
      const clientWidth = scrollContainer.clientWidth;

      // Simply reset when we reach the end
      // Extra 10px to prevent any potential rounding errors
      if (scrollContainer.scrollLeft >= scrollWidth - clientWidth - 10) {
        scrollContainer.scrollLeft = 0;
      } else {
        scrollContainer.scrollLeft += 1;
      }
    };

    // Start new interval
    scrollIntervalIdRef.current = setInterval(scroll, 30);

    return () => {
      if (scrollIntervalIdRef.current) {
        clearInterval(scrollIntervalIdRef.current);
        scrollIntervalIdRef.current = null;
      }
    };
  }, [isDesktopSize]); // Add isDesktopSize as dependency to reset on resize since it was causing carousel to stop scrolling

  const getStartedOnPress = useCallback(() => {
    if (loggedIn === LoginState.LOGGED_IN) {
      dispatch(DataLoaders.user(accountData.uid));
      navigate(`/u/${accountData.username}`, {
        state: { username: accountData.username },
      });
    } else {
      navigate(NavigationId.WELCOME);
    }
  }, [accountData.uid, accountData.username, dispatch, loggedIn, navigate]);

  const pricingOnPress = useCallback(() => {
    navigate(LandingPageNavigationId.PRICING);
  }, [navigate]);

  const textingFeaturesOnPress = useCallback(() => {
    navigate(LandingPageNavigationId.TEXTING);
  }, [navigate]);

  const supportOnPress = useCallback(() => {
    window.open(MARKIT_CONTACT_US);
  }, []);

  // Memoize the CustomerLogoCarousel component to prevent re-renders
  const CustomerLogoCarousel = useCallback(
    () => (
      <div
        ref={logoContainerRef}
        style={{
          display: "flex",
          gap: 40,
          overflow: "hidden",
          width: "100%",
        }}
      >
        {[...Array(2)].map((_, groupIndex) => (
          <div
            key={groupIndex}
            className="AlignedRow"
            style={{ gap: isDesktopSize ? 50 : 28, flexShrink: 0, zIndex: 1 }}
          >
            <img
              src={RecordsCarousel}
              alt="17o1 Records"
              style={{ height: isDesktopSize ? 80 : 45 }}
            />
            <img
              src={RestlessRoadCarousel}
              alt="Restless Road"
              style={{ height: isDesktopSize ? 102 : 58 }}
            />
            <img
              src={TechstarsCarousel}
              alt="Techstars"
              style={{ height: isDesktopSize ? 28 : 16 }}
            />
            <img
              src={BUCarousel}
              alt="BU"
              style={{ height: isDesktopSize ? 50 : 28 }}
            />
            <img
              src={StateChampsCarousel}
              alt="State Champs"
              style={{ height: isDesktopSize ? 102 : 58 }}
            />
            <img
              src={MeetIRLCarousel}
              alt="MeetIRL"
              style={{ height: isDesktopSize ? 35 : 20 }}
            />
            <img
              src={DopeCarousel}
              alt="Dope"
              style={{ height: isDesktopSize ? 53 : 29 }}
            />
            <img
              src={ASAPRockyCarousel}
              alt="A$AP Rocky"
              style={{ height: isDesktopSize ? 102 : 58 }}
            />
            <img
              src={SidetalkCarousel}
              alt="Sidetalk"
              style={{ height: isDesktopSize ? 84 : 47 }}
            />
            <img
              src={OldDominionCarousel}
              alt="Old Dominion"
              style={{ height: isDesktopSize ? 102 : 58 }}
            />
            <img
              src={CAMHCarousel}
              alt="CAMH"
              style={{ height: isDesktopSize ? 45 : 25 }}
            />
            <img
              src={TuftsCarousel}
              alt="Tufts"
              style={{ height: isDesktopSize ? 36 : 20 }}
            />
            <img
              src={SetItOffCarousel}
              alt="Set It Off"
              style={{ height: isDesktopSize ? 102 : 58 }}
            />
            <img
              src={AcesCarousel}
              alt="Aces"
              style={{ height: isDesktopSize ? 53 : 30 }}
            />
            <img
              src={OSLCarousel}
              alt="OSL Falcons"
              style={{ height: isDesktopSize ? 76 : 43 }}
            />
            <img
              src={CitizensCarousel}
              alt="Citizens Bank"
              style={{ height: isDesktopSize ? 23 : 13 }}
            />
          </div>
        ))}
      </div>
    ),
    [isDesktopSize]
  );

  const carouselItems: CarouselItem[] = [
    {
      image: isDesktopSize
        ? JessicaBlumMembership
        : JessicaBlumMembershipMobile,
      title: "",
      width: 884, // padding helps set width for mobile view
    },
    {
      image: isDesktopSize ? ZaneCarterMembership : ZaneCarterMembershipMobile,
      title: "",
      width: 884,
    },
    {
      image: isDesktopSize
        ? AlexJohnsonMembership
        : AlexJohnsonMembershipMobile,
      title: "",
      width: 884,
    },
  ];

  return (
    <LandingPagesWrapper
      helmetTitle={"Home | Markit AI"}
      helmetMetaContent={
        "Markit is a texting platform designed for creators to directly engage their followers and generate sustainable monthly income."
      }
      preventLogoClick
    >
      <div
        className="AlignedColumn"
        style={{
          paddingBlock: isDesktop ? 100 : 60,
          color: Colors.WHITE,
        }}
      >
        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
          }}
        />
        <MarkitHeaderLogo
          fullLogo
          alternateLogo={MarkitFullLogoBlueWhite}
          disabled
        />
        <h1
          style={{
            fontSize: isDesktopSize ? 96 : 63,
            fontWeight: 500,
            paddingBlock: 20,
            textAlign: "center",
            letterSpacing: "-1px",
          }}
        >
          The Texting Platform {isDesktopSize ? <br /> : null}For Creators
        </h1>
        <p
          style={{
            fontSize: 20,
            fontWeight: 500,
            paddingBottom: isDesktopSize ? 40 : 60,
          }}
        >
          Directly engage your fans {isDesktopSize ? null : <br />}and make
          monthly income.
        </p>
        <div
          className={isDesktopSize ? "Centering" : "ColumnNormal"}
          style={{ gap: isDesktopSize ? 14 : 10, width: 164 }}
        >
          <MessageBubble
            text={
              <div className="AlignedRowSelect" style={{ gap: 4 }}>
                <p className="AlignedRowSelect">Start Texting</p>
                <Icon
                  icon="ion:chevron-forward"
                  height={16}
                  color={Colors.WHITE}
                />
              </div>
            }
            tailPosition="bottomLeft"
            onPress={getStartedOnPress}
            containerStyles={{
              borderRadius: 22,
              fontSize: isDesktopSize ? 16 : 12,
              fontWeight: 500,
              padding: "12px 16px",
            }}
          />
          <div className="AlignedRowRight">
            <MessageBubble
              text={"Book a Demo"}
              onPress={supportOnPress}
              containerStyles={{
                borderRadius: 22,
                fontSize: isDesktopSize ? 16 : 12,
                fontWeight: 500,
                padding: "12px 16px",
              }}
              color={Colors.GRAY7}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: isDesktopSize ? 120 : 80,
            width: "100vw",
            marginLeft: "calc(-50vw + 50%)",
          }}
        >
          <CustomerLogoCarousel />
        </div>
      </div>
      {/* Fold 2 */}
      <div
        className={isDesktopSize ? "AlignedColumn" : "ColumnNormal"}
        style={{
          paddingTop: 60,
          paddingBottom: isDesktopSize ? 40 : 60,
          textAlign: isDesktopSize ? "center" : "left",
          backgroundColor: Colors.WHITE,
        }}
      >
        <div
          className={isDesktopSize ? "AlignedColumn" : "ColumnNormal"}
          style={styles.safeView}
        >
          <span
            style={{
              ...styles.headerLabel,
              color: Colors.GREEN2,
            }}
          >
            Monetize with Texting
          </span>
          <h2
            style={{
              ...styles.headerText,
              paddingBottom: 30,
              color: Colors.BLACK,
            }}
          >
            Earn More {isDesktopSize ? null : <br />}From Your <br />
            <div
              className={isDesktopSize ? "ColumnCentering" : "AlignedRow"}
              style={{ maxWidth: 884 }}
            >
              <Carousel
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                showIndicators={false}
                stopOnHover={false}
                autoPlay={true}
                infiniteLoop
                interval={5000}
                transitionTime={500}
                emulateTouch={true}
              >
                {fanWords.map((word, index) => (
                  <span
                    className={isDesktopSize ? "ColumnCentering" : "AlignedRow"}
                    key={index}
                    style={{
                      color: Colors.GREEN2,
                      fontWeight: 600,
                    }}
                  >
                    {word}
                  </span>
                ))}
              </Carousel>
            </div>
          </h2>
          <span
            style={{
              fontSize: isDesktopSize ? 17 : 15,
              fontWeight: 500,
              marginBottom: 60,
              color: Colors.BLACK,
            }}
          >
            Sell merchandise/products, offer paid{" "}
            {isDesktopSize ? null : <br />}
            memberships with {isDesktopSize ? <br /> : null}
            subscription tiers, and create {isDesktopSize ? null : <br />}
            monthly recurring revenue on Markit
          </span>
        </div>
        {/* Carousel container has 20px padding on each side already, which matches the padding on the fold for mobile view */}
        <div className="slide-up">
          <CarouselContainer
            carouselItems={carouselItems}
            containerStyles={{ maxWidth: 884 }}
          />
        </div>
      </div>
      <div style={styles.gradientTopLeft} />
      {/* Fold 3 */}
      <div style={{ maxWidth: 1152, margin: "0 auto" }}>
        <div
          className="AlignedColumnSpaced"
          style={{
            ...styles.safeView,
            paddingTop: isDesktopSize ? 80 : 60,
            paddingBottom: isDesktopSize ? 100 : 60,
            gap: isDesktopSize ? 120 : 60,
          }}
        >
          {/* Reach your Followers instantly */}
          <div
            className={isDesktopSize ? "RowNormal" : "ColumnNormal"}
            style={{ gap: isDesktopSize ? 155 : 50 }}
          >
            <div className="ColumnNormal">
              <span
                style={{
                  ...styles.headerLabel,
                  color: Colors.BLUE5,
                }}
              >
                Texting with Markit
              </span>
              <div className="ColumnNormal" style={{ gap: 30 }}>
                <h2
                  style={{
                    ...styles.headerText,
                    color: Colors.WHITE,
                  }}
                >
                  Reach Your <br />
                  Followers <br />
                  <span
                    className="AlignedRow"
                    style={{ display: "inline-flex" }}
                  >
                    <span style={{ color: Colors.BLUE5, fontWeight: 600 }}>
                      Instantly
                    </span>
                    <Icon
                      icon="iconoir:send-solid"
                      style={{ color: Colors.BLUE5, marginLeft: 8 }}
                    />
                  </span>
                </h2>

                <p style={styles.headerSubtext}>
                  90% of subscribers read your text within 3 minutes, which is
                  way more effective than email or social media. Personalize
                  your texts with emojis, images, and polls.
                </p>

                <RectangleButton
                  buttonLabel="Explore Texting"
                  onPress={textingFeaturesOnPress}
                  altColor={Colors.WHITE}
                  altTextColor={Colors.BLACK}
                  iconRight={
                    <Icon
                      icon="ion:chevron-forward"
                      height={16}
                      color={Colors.BLACK}
                    />
                  }
                  containerStyles={styles.button}
                />
              </div>
            </div>
            <img
              src={ReachYourFansInstantly}
              alt="Markit messaging interface"
              className="slide-up"
              style={{
                ...styles.largeImage,
                objectFit: "contain",
                maxWidth: 519,
              }}
            />
          </div>
          {/* Testimonials */}
          <div
            className={isDesktopSize ? "AlignedRow" : "AlignedColumn"}
            style={{ flex: 1, gap: 40 }}
          >
            <div className="ColumnNormalSpaced" style={styles.testimonialBox}>
              <p style={styles.testimonialText}>
                &quot;Markit is a game-changer—we text our fans, and they
                actually text back! Markit clicked for me when I saw, &apos;I
                named my daughter after this song&apos; and &apos;this song
                helped me through my breakup.&apos; It was so cool.&quot;
              </p>
              <div className="AlignedRow" style={{ gap: 20 }}>
                <img
                  src={OldDominion}
                  alt="old dominion"
                  style={styles.testimonialImage}
                />
                <div>
                  <p style={styles.testimonialName}>Alexa</p>
                  <p style={styles.testimonialName}>Manager, Old Dominion</p>
                </div>
              </div>
            </div>
            <div className="ColumnNormalSpaced" style={styles.testimonialBox}>
              <p style={styles.testimonialText}>
                &quot;I dropped a text about a limited-edition hoodie, and
                within minutes, we saw orders rolling in and fans replying,
                &apos;Just ordered!&apos; and &apos;Literally was waiting for
                this-can&apos;t wait for it to come!&apos; No guessing, people
                were getting our message.&quot;
              </p>
              <div className="AlignedRow" style={{ gap: 20 }}>
                <img src={Aces} alt="aces" style={styles.testimonialImage} />
                <div>
                  <p style={styles.testimonialName}>Brian</p>
                  <p style={styles.testimonialName}>Founder, ACES</p>
                </div>
              </div>
            </div>
          </div>
          {/* iMessage on Steroids */}
          <div
            className={isDesktopSize ? "AlignedRow" : "ColumnNormal"}
            style={{
              gap: isDesktopSize ? 153 : 60,
              width: "100%",
              ...styles.safeView,
            }}
          >
            {isDesktopSize ? (
              <img
                src={iMessageOnSteroids}
                alt="Markit two way conversation interface"
                className="slide-up"
                style={{
                  ...styles.largeImage,
                  objectFit: "contain",
                  maxWidth: 621,
                }}
              />
            ) : null}
            <div className="ColumnNormal">
              <span
                style={{
                  ...styles.headerLabel,
                  color: Colors.BLUE5,
                }}
              >
                Texting with Markit
              </span>
              <div
                className="ColumnNormal"
                style={{ gap: isDesktopSize ? 30 : 0 }}
              >
                <h2
                  style={{
                    ...styles.headerText,
                    color: Colors.WHITE,
                    paddingBottom: isDesktopSize ? 0 : 21,
                  }}
                >
                  iMessage <br />
                  on Steroids
                </h2>
                <p
                  style={{
                    ...styles.headerSubtext,
                    paddingBottom: isDesktopSize ? 0 : 30,
                  }}
                >
                  Create your personal authentic channel to{" "}
                  {isDesktopSize ? null : <br />}connect with your best fans.
                  <br />
                  <br />
                  And because it&apos;s texts, fans can reply like{" "}
                  {isDesktopSize ? null : <br />}they&apos;re chatting with a
                  friend.
                </p>
                <RectangleButton
                  buttonLabel="Explore Two Way"
                  onPress={textingFeaturesOnPress}
                  altColor={Colors.BLUE5}
                  altTextColor={Colors.WHITE}
                  iconRight={
                    <Icon
                      icon="ion:chevron-forward"
                      height={16}
                      color={Colors.WHITE}
                    />
                  }
                  containerStyles={styles.button}
                />
              </div>
            </div>
            {!isDesktopSize ? (
              <img
                src={iMessageOnSteroidsMobile}
                alt="Markit two way conversation interface"
                className="slide-up"
                style={{
                  ...styles.largeImage,
                  objectFit: "contain",
                }}
              />
            ) : null}
          </div>
          {/* Subscriber Testimonials */}
          <img
            src={
              isDesktopSize
                ? JessicaSubscriberQuote
                : JessicaSubscriberQuoteMobile
            }
            alt="Subscriber Quote"
            className="slide-up"
            style={{
              ...styles.largeImage,
              objectFit: "contain",
              maxWidth: 766,
            }}
          />
        </div>
      </div>
      <div style={styles.gradientBottomRight} />
      {/* Fold 4 */}
      <div
        style={{
          ...styles.safeView,
          backgroundColor: Colors.WHITE,
          paddingTop: isDesktopSize ? 80 : 60,
          paddingBottom: isDesktopSize ? 100 : 60,
        }}
      >
        <div style={{ maxWidth: 1152, margin: "0 auto" }}>
          <div
            className={isDesktopSize ? "RowNormal" : "ColumnNormal"}
            style={{ gap: isDesktopSize ? 167 : 40 }}
          >
            <div
              className="ColumnNormal"
              style={{ maxWidth: isDesktopSize ? 383 : undefined }}
            >
              <span
                style={{
                  ...styles.headerLabel,
                  color: Colors.ORANGE1,
                }}
              >
                Grow Your Following
              </span>
              <div className="ColumnNormal" style={{ gap: 30 }}>
                <h2
                  style={{
                    ...styles.headerText,
                    color: Colors.BLACK,
                  }}
                >
                  Your Brand
                  <br />
                  Your Way
                </h2>

                <p style={{ ...styles.headerSubtext, color: Colors.BLACK }}>
                  Every creator has a unique connection with their followers.
                  With a suite of growth tools Markit enables creators to grow
                  with the channel that suits them best.
                </p>

                <RectangleButton
                  buttonLabel="Grow with Markit"
                  onPress={getStartedOnPress}
                  altColor={Colors.BLACK}
                  altTextColor={Colors.WHITE}
                  iconRight={
                    <Icon
                      icon="ion:chevron-forward"
                      height={16}
                      color={Colors.WHITE}
                    />
                  }
                  containerStyles={styles.button}
                />
              </div>
            </div>
            <img
              src={YourBrandYourWay}
              alt="Concert image"
              className="slide-up"
              style={{
                ...styles.largeImage,
                objectFit: "contain",
                maxWidth: 602,
              }}
            />
          </div>
        </div>
      </div>
      {/* Fold 5 */}
      <div
        style={{
          ...styles.safeView,
          backgroundColor: Colors.BLACK,
          paddingTop: isDesktopSize ? 80 : 60,
          paddingBottom: isDesktopSize ? 80 : 60,
        }}
      >
        <div style={{ maxWidth: 1152, margin: "0 auto" }}>
          <div
            className={isDesktopSize ? "RowNormal" : "ColumnNormal"}
            style={{ gap: isDesktopSize ? 149 : 60 }}
          >
            <div className="ColumnNormal">
              <span
                style={{
                  ...styles.headerLabel,
                  color: Colors.BLUE5,
                }}
              >
                Pricing
              </span>
              <div className="ColumnNormal" style={{ gap: 30 }}>
                <h2
                  style={{
                    ...styles.headerText,
                    fontSize: isDesktopSize ? 48 : 45,
                    color: Colors.WHITE,
                  }}
                >
                  Pricing That
                  <br />
                  Scales With You
                </h2>

                <p style={{ ...styles.headerSubtext }}>
                  Markit is free to use. Upgrade to Markit+ to explore
                  additional features and unlock unlimited texting. Contact
                  support to learn more.
                </p>
                <div
                  className="AlignedRow"
                  style={{ gap: isDesktopSize ? 14 : 10 }}
                >
                  <RectangleButton
                    buttonLabel="Pricing"
                    onPress={pricingOnPress}
                    altColor={Colors.WHITE}
                    altTextColor={Colors.BLACK}
                    iconRight={
                      <Icon
                        icon="ion:chevron-forward"
                        height={16}
                        color={Colors.BLACK}
                      />
                    }
                    containerStyles={styles.button}
                  />
                  <RectangleButton
                    buttonLabel="Contact Support"
                    onPress={supportOnPress}
                    altColor={Colors.BLACK}
                    altTextColor={Colors.WHITE}
                    iconRight={
                      <Icon
                        icon="ion:chevron-forward"
                        height={16}
                        color={Colors.WHITE}
                      />
                    }
                    containerStyles={{
                      ...styles.button,
                      border: `1px solid ${Colors.WHITE}`,
                    }}
                  />
                </div>
              </div>
            </div>
            <img
              src={PricingThatScalesWithYou}
              alt="Follower network growth"
              className="slide-up"
              style={{
                ...styles.largeImage,
                objectFit: "contain",
                maxWidth: 545,
              }}
            />
          </div>
        </div>
      </div>
      {/* Fold 6 */}
      <div
        className={"AlignedColumn"}
        style={{
          paddingTop: isDesktopSize ? 80 : 60,
          paddingBottom: isDesktopSize ? 80 : 60,
          backgroundImage: `url(${BackgroundGradient})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h2
          style={{
            ...styles.headerText,
            paddingBottom: 30,
            color: Colors.WHITE,
            textAlign: "center",
          }}
        >
          Text Marketing {isDesktopSize ? null : <br />}That{" "}
          {isDesktopSize ? <br /> : null}Feels {isDesktopSize ? null : <br />}
          <span style={{ color: Colors.BLUE5, fontWeight: 600 }}>Human</span>
        </h2>
        <RectangleButton
          buttonLabel={
            <p
              className="AlignedRowSelect"
              style={{
                fontSize: isDesktopSize ? 16 : 12,
                whiteSpace: "nowrap",
                fontWeight: 500,
              }}
            >
              Start Texting
            </p>
          }
          onPress={getStartedOnPress}
          altColor={Colors.BLUE5}
          altTextColor={Colors.WHITE}
          containerStyles={{
            ...styles.button,
            alignSelf: "center",
          }}
          iconRight={
            <Icon icon="ion:chevron-forward" height={16} color={Colors.WHITE} />
          }
        />
        <img
          src={TextingMadeHuman}
          alt="Creator Subscriber Collage"
          className="slide-up"
          style={{
            ...styles.largeImage,
            paddingTop: 60,
            objectFit: "contain",
            maxWidth: isDesktopSize ? 480 : 336,
          }}
        />
      </div>
    </LandingPagesWrapper>
  );
});

export default DefaultLandingPage;
