import React, { memo } from "react";
import { useTheme } from "../../hooks/useTheme";
import { ThemeType } from "@markit/common.types";

type HorizontalDividerProps = {
  altMargin?: number;
  altWidth?: number;
  altColor?: string;
  eventTheme?: ThemeType;
  containerStyles?: React.CSSProperties;
};

export const HorizontalDivider = memo(function HorizontalDividerFn(
  props: HorizontalDividerProps
) {
  const { altMargin, altWidth, altColor, eventTheme, containerStyles } = props;
  const { theme } = useTheme(eventTheme);

  return (
    <div style={{ ...containerStyles }}>
      <hr
        style={{
          marginBlock: altMargin ?? 0,
          borderColor: altColor ?? theme.DividerColor.borderColor,
          borderWidth: altWidth ?? 1.5,
        }}
      />
    </div>
  );
});
