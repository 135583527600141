import { isDesktop } from "react-device-detect";
import {
  MIN_WIDTH_FOR_MOBILE,
  MIN_WIDTH_FOR_MOBILE_LARGER,
} from "./constants/globalConstants";

export const checkIsDesktop = (width: number, larger?: boolean) => {
  return (
    isDesktop &&
    width >= (larger ? MIN_WIDTH_FOR_MOBILE_LARGER : MIN_WIDTH_FOR_MOBILE)
  );
};
