import { useEffect, useState, useCallback } from "react";
import { MessageBubble } from "./MessageBubble";
import { isDesktop } from "react-device-detect";
import { useWindowResize } from "../../hooks/useWindowResize";

interface BubbleData {
  text: string;
  x: string;
  y: number;
  blurred: boolean;
  dark: boolean;
  large: boolean;
  width?: number;
}

interface BubblePosition {
  id: number;
  message: BubbleData;
  floatOffset: number;
  _floatY?: number;
  _adjustedX?: string;
}

// Define desktop message positions
const messages: BubbleData[] = [
  // Top Left section
  {
    text: "Please text me for the next event",
    x: "8%",
    y: 150,
    blurred: false,
    dark: true,
    large: false,
  },
  {
    text: "LOVED the concert. Send merch!!!!!",
    x: "20%",
    y: 205,
    blurred: false,
    dark: false,
    large: true,
  },
  {
    text: "ddddddddddd",
    x: "45%",
    y: 180,
    blurred: true,
    dark: true,
    large: false,
  },
  {
    text: "I am your biggest fan",
    x: "53%",
    y: 170,
    blurred: false,
    dark: true,
    large: false,
  },

  // Top Right section
  {
    text: "Love these texts!!",
    x: "67%",
    y: 230,
    blurred: false,
    dark: false,
    large: true,
  },
  {
    text: "Your texts are so motivating",
    x: "73%",
    y: 140,
    blurred: false,
    dark: false,
    large: false,
  },
  {
    text: "ddddddddddddddd",
    x: "81%",
    y: 210,
    blurred: true,
    dark: true,
    large: false,
  },

  {
    text: "Thanks for all the tips!",
    x: "79%",
    y: 310,
    blurred: false,
    dark: false,
    large: true,
  },
  {
    text: "Love chatting over text",
    x: "76%",
    y: 380,
    blurred: false,
    dark: true,
    large: false,
  },
  {
    text: "An unreleased song?! Can't wait to hear it! When is it dropping?",
    x: "79%",
    y: 480,
    blurred: false,
    dark: false,
    large: false,
    width: 173,
  },

  // Bottom Right section
  {
    text: "ddddddddddddddd",
    x: "75%",
    y: 550,
    blurred: true,
    dark: true,
    large: false,
  },
  {
    text: "Hooray!!!!",
    x: "85%",
    y: 730,
    blurred: false,
    dark: true,
    large: false,
  },
  {
    text: "Just upgraded to Platinum! So excited to support your channel. How else can we...",
    x: "60%",
    y: 650,
    blurred: false,
    dark: false,
    large: true,
    width: isDesktop ? 304 : 238,
  },
  {
    text: "How much are tickets?",
    x: "55%",
    y: 760,
    blurred: false,
    dark: true,
    large: true,
  },
  {
    text: "Ahhh, I love your Q&As! When's the next one??",
    x: "35%",
    y: 720,
    blurred: false,
    dark: false,
    large: true,
    width: isDesktop ? 175 : 140,
  },

  // Bottom Right section
  {
    text: "Where is the best parking?",
    x: "30%",
    y: 650,
    blurred: false,
    dark: true,
    large: false,
  },
  {
    text: "ddddddddddddddd",
    x: "17%",
    y: 690,
    blurred: true,
    dark: true,
    large: true,
  },
  {
    text: "ddddddd",
    x: "5%",
    y: 570,
    blurred: true,
    dark: true,
    large: false,
  },
  {
    text: "When are you going on tour and where can I get your new book?",
    x: "7%",
    y: 540,
    blurred: false,
    dark: false,
    large: true,
    width: 236,
  },
  {
    text: "Wow. You were AMAZING",
    x: "7%",
    y: 440,
    blurred: false,
    dark: true,
    large: false,
  },
  {
    text: "Excited for new videos!",
    x: "3%",
    y: 340,
    blurred: false,
    dark: false,
    large: true,
  },
];

export const FloatingBubbles = () => {
  const [bubbles, setBubbles] = useState<BubblePosition[]>([]);
  const windowWidth = useWindowResize();

  // Function to adjust bubble positions based on window width
  const adjustBubblePositions = useCallback(
    (width: number, currentBubbles: BubblePosition[]) => {
      return currentBubbles.map((bubble) => {
        const originalX = parseFloat(bubble.message.x);
        let adjustedX = originalX;
        const y = bubble.message.y;

        // Calculate threshold for center zone
        const centerYTopThreshold = 300;
        const centerYBottomThreshold = 620;

        // Calculate shift amount based on width reduction from base width
        const baseWidth = 1400;

        const widthReduction = width > baseWidth ? 0 : baseWidth - width;
        const reductionFactor = 6;
        const widthReductionPercent = widthReduction / baseWidth;

        // Handle bubbles based on their y position
        if (y >= centerYTopThreshold && y <= centerYBottomThreshold) {
          // Center zone bubbles - push completely off screen based on which side they're closer to
          if (originalX < 50) {
            // Left side of center - push left off screen
            adjustedX = originalX - widthReduction / reductionFactor;
          } else {
            // Right side of center - push right off screen
            adjustedX = originalX + widthReduction / reductionFactor;
          }
        } else {
          if (originalX < 50) {
            // Left side of center - push left by a percentage
            adjustedX = originalX * (1 - widthReductionPercent);
          } else {
            // Right side of center - Doesn't change
            adjustedX = originalX;
          }
        }

        return {
          ...bubble,
          _adjustedX: `${adjustedX}%`,
        };
      });
    },
    []
  );

  useEffect(() => {
    // Initialize bubble positions with animation parameters
    const initialBubbles: BubblePosition[] = messages.map((message, index) => {
      const floatOffset = Math.random() * Math.PI * 2; // Random starting point in the float cycle

      const bubble: BubblePosition = {
        id: index,
        message,
        floatOffset,
      };
      return bubble;
    });

    setBubbles(initialBubbles);

    // Animation loop for subtle up/down floating effect
    let animationFrameId: number;
    let startTime = 0;

    const animate = (time: number) => {
      if (!startTime) startTime = time;
      const elapsedTime = time - startTime;

      setBubbles((prevBubbles) =>
        prevBubbles.map((bubble) => {
          // Calculate subtle up/down floating movement
          const floatY = Math.sin(elapsedTime / 1400 + bubble.floatOffset) * 6;

          return {
            ...bubble,
            floatOffset: bubble.floatOffset,
            _floatY: floatY,
          };
        })
      );

      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  // Apply responsive positioning when window width changes
  useEffect(() => {
    if (windowWidth > 0 && bubbles.length > 0) {
      setBubbles((prevBubbles) =>
        adjustBubblePositions(windowWidth, prevBubbles)
      );
    }
  }, [windowWidth, adjustBubblePositions, bubbles.length]);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        zIndex: 1,
        overflowX: "clip", // prevents horizontal overflow from being visible
      }}
    >
      {bubbles.map((bubble) => (
        <div
          key={bubble.id}
          style={{
            position: "absolute",
            left: bubble._adjustedX || bubble.message.x,
            top: bubble.message.y,
            transform: `translateY(${bubble._floatY || 0}px)`,
            transition: "transform 0.5s ease-out, left 0.5s ease-out",
          }}
        >
          <MessageBubble
            text={bubble.message.text}
            width={bubble.message.width}
            visualStyles={{
              opacity: bubble.message.dark ? 0.4 : 1,
              filter: bubble.message.blurred ? "blur(10px)" : "none",
            }}
            containerStyles={{
              borderRadius: bubble.message.large ? 22 : 16,
              fontSize: bubble.message.large
                ? isDesktop
                  ? 16
                  : 12
                : isDesktop
                ? 11
                : 10,
              fontWeight: 500,
              paddingInline: bubble.message.large ? 16 : 10,
              paddingBlock: bubble.message.large ? 12 : 7,
            }}
          />
        </div>
      ))}
    </div>
  );
};
