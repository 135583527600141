import React from "react";
import { Colors } from "../../../../utils/colors";
import CustomTextField from "../../../CustomTextField";
import UploadSpreadsheetFile from "./UploadSpreadsheetFile";
import RectangleButton from "../../../Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import { SpreadsheetFileDisplay, SpreadsheetInfo } from "@markit/common.types";
import StandardBorderedContainer from "../../../Containers/StandardBorderedContainer";
import SwitchToggleItem from "../../../SwitchToggleItem";
import { MARKIT_DOCS } from "@markit/common.utils";

type UploadContactsProps = {
  spreadsheet: SpreadsheetInfo | undefined;
  setSpreadsheet: (spreadsheet: SpreadsheetInfo | undefined) => void;
  currNumImported: number;
  displayFile: SpreadsheetFileDisplay;
  setDisplayFile: (displayFile: SpreadsheetFileDisplay) => void;
  permissionsChecked: boolean;
  setPermissionsChecked: (permissionsChecked: boolean) => void;
  includeExistingContacts: boolean;
  setIncludeExistingContacts: (includeExistingContacts: boolean) => void;
};

const UploadContacts = (props: UploadContactsProps) => {
  const {
    spreadsheet,
    setSpreadsheet,
    currNumImported,
    displayFile,
    setDisplayFile,
    permissionsChecked,
    setPermissionsChecked,
    includeExistingContacts,
    setIncludeExistingContacts,
  } = props;

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    subtext: { fontSize: 14, color: Colors.GRAY1 },
    bodyMedium: { fontSize: 14, fontWeight: 500 },
  };

  return (
    <div className="ColumnNormal Centering" style={{ paddingBottom: 40 }}>
      {spreadsheet ? (
        <div className="ColumnNormal" style={{ gap: 24, paddingTop: 24 }}>
          <div className="ColumnNormal" style={{ gap: 7, textAlign: "center" }}>
            <span style={styles.headerText}>Review File Details</span>
            <span style={styles.subtext}>
              Choose a file display name and review before continuing.
            </span>
          </div>
          <div
            className="ColumnNormal"
            style={{ gap: 7, width: 547, paddingBottom: 24 }}
          >
            <span style={{ ...styles.bodyMedium, textAlign: "left" }}>
              Display Name
            </span>
            <div className="ColumnNormal" style={{ gap: 4 }}>
              <CustomTextField
                value={displayFile.name}
                placeholder="Display Name..."
                inputMode="text"
                borderRadius={12}
                backgroundColor={Colors.WHITE1}
                onChange={(change: any) => {
                  setDisplayFile({
                    name: change.target.value,
                    isError: false,
                    message: "",
                  });
                }}
                error={displayFile.isError}
                altMarginBottom={0}
              />
              {displayFile.isError ? (
                <span style={{ fontSize: 12, color: Colors.RED2 }}>
                  {displayFile.message}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <div className="ColumnNormal Centering" style={{ gap: 24, width: 547 }}>
        <UploadSpreadsheetFile
          spreadsheet={spreadsheet}
          setSpreadsheet={setSpreadsheet}
          displayFile={displayFile}
          setDisplayFile={setDisplayFile}
          permissionsChecked={permissionsChecked}
          setPermissionsChecked={setPermissionsChecked}
          currNumImported={currNumImported}
        />
        {spreadsheet && spreadsheet.existingContactRows.length > 0 ? (
          <StandardBorderedContainer
            containerStyles={{
              padding: 14,
              backgroundColor: Colors.WHITE1,
            }}
          >
            <SwitchToggleItem
              title={`Include Already Existing Contacts (${spreadsheet.existingContactRows.length})`}
              description={
                "Include any duplicate contacts from your spreadsheet that had been previously uploaded who were already in your audience."
              }
              toggleValue={includeExistingContacts}
              onChange={() =>
                setIncludeExistingContacts(!includeExistingContacts)
              }
            />
          </StandardBorderedContainer>
        ) : null}
        {!spreadsheet ? (
          <RectangleButton
            buttonLabel={
              <span style={styles.bodyMedium}>How to Format Spreadsheet</span>
            }
            altColor={Colors.GRAY6}
            altTextColor={Colors.BLACK}
            onPress={() => window.open(MARKIT_DOCS + "/importcsv")}
            iconRight={
              <Icon icon="mdi:chevron-right" height={16} color={Colors.BLACK} />
            }
            width={248}
            containerStyles={{ marginTop: 10 }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default UploadContacts;
